import { QueryRandomizer } from "../../api/search/query-randomizer";

export function useQueryRandomizer() {
    const randomizer = new QueryRandomizer();

    const randomizeQuery = () => {
        return randomizer.randomize();
    }

    return {
        randomizeQuery
    }
}