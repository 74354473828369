import { useState, useEffect } from 'react';
import { useQueryRandomizer } from './query-randomizer.hook';
import { useBookSearcher } from './book-searcher';
import { Book } from '../../models/book.model';


export const useRandomBooks = (num: number) => {
    const [books, setBooks] = useState<Book[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const { randomizeQuery } = useQueryRandomizer();
    const { bookSearcher } = useBookSearcher();

    useEffect(() => {
        const fetchBooks = async () => {
            const promises = Array.from({ length: num }, async () => {
                let query = randomizeQuery();
                let books: Book[] | undefined = undefined;

                while (!books || books.length === 0) {
                    try {
                        books = await bookSearcher.search({ title: query, limit: 50 });
                    }
                    catch (error) {
                        // do nothing
                    }

                    query = randomizeQuery();
                }

                return books[Math.floor(Math.random() * books.length)];
            });

            try {
                setLoading(true);
                const books = await Promise.all(promises);
                setBooks(books);
            }
            catch (error) {
                if (error instanceof Error)
                    setError(error);
                else {
                    console.error(error);
                    setError(new Error("An unknown error occurred"));
                }
            } finally {
                setLoading(false);
            }

        };

        fetchBooks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return {
        books,
        error,
        loading
    };
};

