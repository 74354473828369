import StickyTopBar from "../../components/top-bar/sticky-top-bar";
import { useAuth } from "../../hooks/auth-hook";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export interface AuthProps {
  children: React.ReactNode;
}

export default function Auth({ children }: AuthProps) {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user, navigate]);

  return (
    <div className="min-h-screen flex flex-col">
      <StickyTopBar />
      <main className="flex-grow p-4 w-full items-center justify-center flex">
        <div className="w-full">{children}</div>
      </main>
    </div>
  );
}
