// styled components there for the app
// colors are #243763, #FF6E31, #FFEBB7, #AD8E70
// font is 'Roboto', sans-serif;

export const theme = {
  colors: {
    primary: "#243763",
    secondary: "#FF6E31",
    tertiary: "#FFEBB7",
    quaternary: "#AD8E70",
  },
  fonts: {
    primary: "Roboto, sans-serif",
  },
};
