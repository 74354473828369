import { generate } from "random-words";

export class QueryRandomizer {
    randomize() {
        const words = generate({
            maxLength: 3
        })
        const query = Array.isArray(words) ? words.join(' ') : words;

        return query;
    }
}