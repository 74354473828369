const PrivacyPolicy = () => {
    return (
        <div className="max-w-4xl mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold text-center mb-8">Privacy Policy</h1>
            <div className="space-y-6">
                <section>
                    <h2 className="text-2xl font-semibold mb-2">Introduction</h2>
                    <p className="text-base">
                        Welcome to PickABook.me! We are committed to creating an engaging and safe platform for users to randomly discover new books. This Privacy Policy outlines our practices regarding the collection, use, and sharing of information. By accessing or using our website, you agree to the terms of this Privacy Policy.
                    </p>
                </section>
                <section>
                    <h2 className="text-2xl font-semibold mb-2">Information Collection</h2>
                    <p className="text-base">
                        PickABook.me collects anonymous data solely for analytics purposes. This helps us to improve website usability and enhance your browsing experience. No personal information is collected.
                    </p>
                </section>
                <section>
                    <h2 className="text-2xl font-semibold mb-2">Use of Information</h2>
                    <p className="text-base">
                        The anonymous data collected is used to analyze website usage and improve site functionality. Additionally, we use Google AdSense to display ads on our website. Google may collect certain data; for more information, please refer to Google's privacy policy.
                    </p>
                </section>
                <section>
                    <h2 className="text-2xl font-semibold mb-2">Information Sharing</h2>
                    <p className="text-base">
                        We do not share any personal information with third parties. The anonymous data collected may be shared with Google for advertising purposes.
                    </p>
                </section>
                <section>
                    <h2 className="text-2xl font-semibold mb-2">User Rights and Choices</h2>
                    <p className="text-base">
                        Users are informed about and can consent to the use of cookies through a consent form available on our website. If you wish to opt-out of data collection, please refer to the consent form instructions.
                    </p>
                </section>
                <section>
                    <h2 className="text-2xl font-semibold mb-2">Cookies and Tracking Technologies</h2>
                    <p className="text-base">
                        Cookies are utilized on our website for user authentication purposes. This is to enhance user experience by maintaining session information.
                    </p>
                </section>
                <section>
                    <h2 className="text-2xl font-semibold mb-2">International Data Transfer</h2>
                    <p className="text-base">
                        Our website is hosted in the United States. By using our website, you consent to the transfer of data to the United States.
                    </p>
                </section>
                <section>
                    <h2 className="text-2xl font-semibold mb-2">Policy Updates and Notification</h2>
                    <p className="text-base">
                        We reserve the right to update this Privacy Policy at any time. Users will be notified of any changes through a notice on our website.
                    </p>
                </section>
                <section>
                    <h2 className="text-2xl font-semibold mb-2">Contact Information</h2>
                    <p className="text-base">
                        If you have any questions or concerns about our Privacy Policy, please contact us at&nbsp;
                        <a href="mailto:contact.pickabook@gmail.com" className="text-blue-600 hover:text-blue-800 visited:text-purple-600">
                            contact.pickabook@gmail.com
                        </a>.
                    </p>
                </section>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
