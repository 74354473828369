import { useCallback, useEffect, useState } from "react";
import { User, onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";

export interface AuthState {
  loaded: boolean;
  user?: User | null;
}

export function useAuth() {
  const [authState, setAuthState] = useState<AuthState>({
    loaded: false,
  });

  const stateChanged = useCallback(
    (user: User | null) => {
      setAuthState({ loaded: true, user });
    },
    [setAuthState]
  );

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, stateChanged);

    return () => {
      unsubscribe();
    };
  }, [stateChanged]);

  return authState;
}
