import React, { useState } from "react";
import { Link, Outlet } from "react-router-dom";

const Books = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const bookList = [
    { id: "1", title: "Book 1" },
    { id: "2", title: "Book 2" },
    { id: "3", title: "Book 3" },
  ];

  const filteredBooks = bookList.filter((book) =>
    book.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="flex p-4">
      <div className="w-1/4 pr-4">
        <input
          type="text"
          placeholder="Search books..."
          className="mb-4 w-full px-2 py-1 border border-gray-300 rounded"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <ul className="list-none m-0 p-0">
          {filteredBooks.map((book) => (
            <li key={book.id} className="mb-2">
              <Link
                to={book.id}
                className="text-blue-600 hover:text-blue-800 visited:text-purple-600"
              >
                {book.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="w-3/4">
        <Outlet />
      </div>
    </div>
  );
};

export default Books;
