import { useEffect, useState } from "react";
import { BookDetailed } from "../models/book-detailed.model";
import { useBookSearcher } from "./book-search/book-searcher";

export function useBook(bookId?: string): { book?: BookDetailed } {
    const [book, setBook] = useState<BookDetailed>();
    const { bookSearcher } = useBookSearcher();

    useEffect(() => {
        const fetchBook = async (bookId: string) => {
            const book = await bookSearcher.getBook(bookId);

            setBook(book);
        };

        console.log('b', bookId);
        if (bookId) {
            fetchBook(bookId);
        }
    }, [bookId, bookSearcher]);

    return { book };
}