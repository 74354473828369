import { BookDetailed } from "../models/book-detailed.model";
import { Book } from "../models/book.model";
import { OpenLibraryBookResponse, OpenLibrarySearchResponse } from "../models/open-library.model";
import { BookSearcher } from "./search/book-searcher";
import { SearchParams } from "./search/search-params";

const FIELDS = ["key", "title", "author_name", "cover_i", "first_sentence", "subject"];

export class OpenLibraryBookSearcher implements BookSearcher {
    async search(params: SearchParams): Promise<Book[]> {
        const response = await fetch(`https://openlibrary.org/search.json?title=${params.title}&limit=${params.limit}&fields=${FIELDS.join(",")}`);
        const data = await response.json() as OpenLibrarySearchResponse;

        return data.docs.map((doc: any) => {
            return {
                id: doc.key.split("/").pop()!,
                title: doc.title,
                author: doc.author_name ? doc.author_name[0] : "",
                image: `https://covers.openlibrary.org/b/id/${doc.cover_i}-L.jpg`,
                description: doc.first_sentence ? doc.first_sentence[0] : "",
                genres: doc.subject,
            }
        });
    }

    async getBook(id: string): Promise<BookDetailed> {
        const response = await fetch(`https://openlibrary.org/works/${id}.json`, { headers: { Accept: "application/json" } });
        const data = await response.json() as OpenLibraryBookResponse;

        return {
            id,
            title: data.title,
            author: data.authors[0].author.key,
            authors: data.authors.map(a => a.author.key),
            description: data.description?.value,
            image: data.covers ? `https://covers.openlibrary.org/b/id/${data.covers[0]}-L.jpg` : "",
            genres: data.subjects,
        }
    }
}