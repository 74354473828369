import { Book } from "../../models/book.model";
import BookCard2 from "./book-card-2";

type BookShowcaseProps = {
  books: Book[];
};

const BookShowcase: React.FC<BookShowcaseProps> = ({ books }) => {
  return (
    <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
      {books.map((book) => (
        <div key={book.id} className="md:flex-1">
          <BookCard2 book={book} />
        </div>
      ))}
    </div>
  );
};

export default BookShowcase;
