export type Genre =
  | "Fantasy"
  | "Science Fiction"
  | "Mystery"
  | "Romance"
  | "Horror"
  | "Tragedy";

const genreColors: { [key in Genre]: string } = {
  Fantasy: "bg-purple-200 text-purple-800",
  "Science Fiction": "bg-blue-200 text-blue-800",
  Mystery: "bg-green-200 text-green-800",
  Romance: "bg-pink-200 text-pink-800",
  Horror: "bg-red-200 text-red-800",
  Tragedy: "bg-gray-800 text-gray-200",
};

export const getGenreColor = (genre: string) => {
  if (genre in genreColors) {
    return genreColors[genre as Genre];
  }

  return "bg-gray-200 text-gray-800";
};
