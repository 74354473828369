import { Link, NavLink } from "react-router-dom";
import { ReactComponent as Ghost } from "../../images/ghost.svg";

export const BottomBar = () => {
    return (
        <footer className="bg-secondary-500 text-lg p-3 z-50">
            <nav className="container mx-auto flex justify-between items-center">
                <div className="flex items-center">
                    <Ghost className="h-10 w-10 mr-2 fill-current" />
                    <Link to="/" className="font-bold uppercase">
                        pickaBook
                    </Link>
                </div>
                <div className="hidden md:flex gap-4">
                    <NavLink to="/privacy">Privacy</NavLink>
                </div>
            </nav>
        </footer>
    );
}