import React from "react";

export default function NotFound() {
  return (
    <div className="flex flex-col h-screen">
      <div className="flex-grow flex items-center justify-center bg-gray-100">
        <div className="text-center">
          <h1 className="text-6xl font-bold text-gray-800">404</h1>
          <p className="text-gray-600 mt-2 text-lg">Page not found</p>
        </div>
      </div>
    </div>
  );
}
