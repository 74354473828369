import { Outlet } from "react-router-dom";
import StickyTopBar from "../components/top-bar/sticky-top-bar";
import { BottomBar } from "../components/bottom-bar/bottom-bar";

export default function Root() {
  return (
    // <div className="min-h-screen bg-gray-50">
    //   <StickyTopBar />
    //   <div id="content" className="p-4 min-h-screen">
    //     <Outlet />
    //   </div>
    // </div>

    <div className="min-h-screen flex flex-col bg-neutral-50">
      <StickyTopBar />
      <main className="flex-grow w-full">
        <Outlet />
      </main>
      <BottomBar />
    </div>
  );
}
