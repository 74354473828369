import HomeSection from "../../components/ui/home-section";
import { RandomBooksShowcase } from "../../components/books/random-books-showcase";

const Home = () => {
  return (
    <div>
      {/* <HomeSection backgroundColor="bg-grey-100">
        <TransformationStage />
      </HomeSection> */}
      {/* alternating bg color */}
      <HomeSection backgroundColor="bg-blue-100">
        <RandomBooksShowcase booksNum={5} />
      </HomeSection>
      <HomeSection backgroundColor="bg-grey-100">
        <RandomBooksShowcase booksNum={5} />
      </HomeSection>
      <HomeSection backgroundColor="bg-blue-100">
        <RandomBooksShowcase booksNum={5} />
      </HomeSection>
    </div>
  );
};

export default Home;
