import { useRandomBooks } from "../../hooks/book-search/random-book-search.hook";
import BookShowcase from "./books-showcase";
import { Loader } from "../loader/loader";

export interface RandomBooksShowcaseProps {
    booksNum: number;
}

export const RandomBooksShowcase = ({ booksNum }: RandomBooksShowcaseProps) => {
    const { books, loading } = useRandomBooks(booksNum);

    return (
        loading ? <Loader /> : <BookShowcase books={books} />
    );
};