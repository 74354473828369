import { Link } from "react-router-dom";
import { ReactComponent as Ghost } from "../../images/ghost.svg";

const StickyTopBar = () => {
  return (
    <header className="bg-primary-500 text-white text-lg p-3 sticky top-0 z-50">
      <nav className="container mx-auto flex justify-between items-center">
        <div className="flex items-center">
          <Ghost className="h-10 w-10 mr-2 text-white fill-current" />
          <Link to="/" className="font-bold uppercase">
            Pick a Book
          </Link>
        </div>
        <div className="hidden md:flex gap-4">
          {/* <NavButton path="/">Home</NavButton>
          <NavButton path="/books">Books</NavButton>
          <NavButton path="/library">Library</NavButton>
          <NavButton path="/exchange">Exchange</NavButton> */}
        </div>
        <div className="md:hidden">
          {/* Hamburger menu icon for smaller screens */}
        </div>
        {/* <div className="hidden md:flex">
          <Link to="/signin" className="text-white">
            Log in →
          </Link>
        </div> */}
        {/* <AuthBadge /> */}
      </nav>
    </header>
  );
};

export default StickyTopBar;
