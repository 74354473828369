// BookActions.tsx
import React from "react";
import { Link } from "react-router-dom";
import { Book } from "../../models/book.model";

export interface BookActionsProps {
  book: Book;
}

const BookActions: React.FC<BookActionsProps> = ({ book }) => {
  return (
    <div className="flex justify-around mt-4">
      {/* <Link
        to="/"
        className="text-gray-600 hover:text-gray-800 underline text-sm py-2 px-4"
      >
        Buy
      </Link>
      <Link
        to="/"
        className="text-gray-600 hover:text-gray-800 underline text-sm py-2 px-4"
      >
        Exchange
      </Link> */}
      <Link
        to={`/book/${book.id}`}
        className="text-gray-600 hover:text-gray-800 underline text-sm py-2 px-4"
      >
        More
      </Link>
    </div>
  );
};

export default BookActions;
