import React, { ReactNode } from "react";

type HomeSectionProps = {
  backgroundColor: string;
  children: ReactNode;
};

const HomeSection: React.FC<HomeSectionProps> = ({
  backgroundColor,
  children,
}) => {
  return <div className={`${backgroundColor} p-8 my-4`}>{children}</div>;
};

export default HomeSection;
