import React from "react";
import { useParams } from "react-router-dom";
import { useBook } from "../../hooks/book.hook";
import { Loader } from "../../components/loader/loader";

const Book = () => {
  const { bookId } = useParams();
  const { book } = useBook(bookId);

  if (!book) {
    return <div className="flex items-center justify-center h-screen"><Loader /></div>;
  }

  return (
    <div className="bg-gray-100 p-4 rounded-lg shadow flex items-start space-x-4">
      <img
        src={book.image}
        alt={book.title}
      />
      <div>
        <h1 className="text-lg font-semibold">{book.title}</h1>
        <p className="text-sm text-gray-600">{book.description}</p>
      </div>
    </div>

  );
};

export default Book;
