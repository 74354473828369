import { RequestLocation } from "../../components/exchange/request-location";
import { useGeoLocation } from "../../hooks/getlocation-hook";

export function Exchange() {
  const { error, location } = useGeoLocation();

  if (error || !location) {
    return <RequestLocation />;
  }

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <h1 className="text-4xl font-bold">Exchange</h1>
    </div>
  );
}
