// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  browserLocalPersistence,
  getAuth,
  setPersistence,
} from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyACMdvSiH-v-96vG9EoNXnAQv2jqFiE6jI",
  authDomain: "pickabook-278ec.firebaseapp.com",
  projectId: "pickabook-278ec",
  storageBucket: "pickabook-278ec.appspot.com",
  messagingSenderId: "267723516588",
  appId: "1:267723516588:web:b010e8c941ce07dd8b8d09",
  measurementId: "G-K8DZV2E1LL",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);

setPersistence(auth, browserLocalPersistence);
