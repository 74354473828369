// BookCard.tsx
import React from "react";
import { Book } from "../../models/book.model";
import { getGenreColor } from "../../functionality/genres";
import Divider from "../ui/divider";
import BookActions from "./book-actions-2";

const BookCard: React.FC<{ book: Book }> = ({ book }) => {
  return (
    <div className="relative rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-all duration-300 h-80">
      {/* Book Image as Background */}
      <img
        src={book.image}
        alt={book.title}
        className="object-cover w-full h-full pointer-events-none"
      />

      {/* Overlay with Title, Description, Genre Chips, and Actions */}
      <div className="absolute bottom-0 w-full h-1/4 bg-white bg-opacity-90 p-4 hover:h-3/4 transition-all duration-300 overflow-y-auto">
        <h2 className="text-lg font-bold">{book.title}</h2>
        <div className="flex flex-wrap gap-2 mt-2">
          {/* Genre Chips */}
          {book.genres &&
            book.genres.slice(0, 2).map((genre) => (
              <span
                key={genre}
                className={`text-xs font-semibold py-1 px-2 rounded-full ${getGenreColor(
                  genre
                )}`}
              >
                {genre}
              </span>
            ))}
        </div>
        <p className="text-sm text-gray-700 mt-2 line-clamp-3">
          {book.description}
        </p>
        <Divider />
        <BookActions book={book} />
      </div>
    </div>
  );
};

export default BookCard;
