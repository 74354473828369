import React from "react";
import { GlobeAltIcon } from "@heroicons/react/24/solid";

export const RequestLocation = () => {
  const requestPermissions = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        console.log("Location access granted");
        // Handle successful location retrieval
      },
      (error) => {
        console.error("Location access denied");
        // Handle error or denial
      }
    );
  };

  return (
    <div className="flex flex-col items-center justify-center p-6 m-6 bg-white rounded-lg shadow-md">
      <GlobeAltIcon className="w-20 h-20 text-blue-500" />
      <button
        onClick={requestPermissions}
        className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
      >
        Grant Location Access
      </button>
      <p className="mt-2 text-sm text-gray-600">
        We need your location to provide better services.
      </p>
    </div>
  );
};
