import { createBrowserRouter } from "react-router-dom";
import Root from "./root";
import NotFound from "../components/errors/not-found";
import Books from "./pages/books";
import Book from "./pages/book";
import Auth from "./pages/auth";
import SignIn from "../components/auth/signin";
import SignUp from "../components/auth/signup";
import Home from "./pages/home";
import { Exchange } from "./pages/exchange";
import PrivacyPolicy from "./pages/privacy-policy";

export const router = createBrowserRouter([
  {
    path: "/signin",
    element: (
      <Auth>
        <SignIn />
      </Auth>
    ),
  },
  {
    path: "/signup",
    element: (
      <Auth>
        <SignUp />
      </Auth>
    ),
  },
  {
    path: "/",
    element: <Root />,
    errorElement: <NotFound />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "book/:bookId",
        element: <Book />,
      },
      {
        path: "books",
        element: <Books />,
        children: [
          {
            path: ":bookId",
            element: <Book />,
          },
        ],
      },
      {
        path: "find",
        element: <Books />,
        children: [
          {
            path: "beginner",
            element: <Book />,
          },
          {
            path: "intermediate",
            element: <Book />,
          },
          {
            path: "advanced",
            element: <Book />,
          },
        ],
      },
      {
        path: "exchange",
        element: <Exchange />,
      },
      {
        path: "journy",
        element: <div>journy</div>,
      },
      {
        path: "privacy",
        element: <PrivacyPolicy />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);
